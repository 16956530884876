import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from './router/index'
import {
  Loading,
  Notification
} from 'element-ui';
import {getAgentAccInfo,getAgentAccDetail,getAgentAccDetailSource,getUserUnreadMessageCount} from './api/http'
// import { clearAllCookie } from './api/clearAllCookie';
import { delCookie } from './api/delCookie';
// import {clearAllCookie} from './api/clearAllCookie'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  // sidebarMinimize: false,
  sidebarMinimize: true,
  userInfo: {},
  dashboardData: {},
  agentp: "",
  agentAccData: "",
  dlAccTbData: [],
  dlAccObj: "",
  // 代理账户明细
  rewardDetail: [],
  source: {},
  sourceLoading: true,
  // 页数总数
  pageTotal: null,
  // 未读私信数量
  unreadCount: null,
  isShowBadge: false,
  visiQrCode: false, //全局Telegram二维码显示
  visiChannelQrCode: false, // 全局官方频道二维码显示
  intervalId: null, // 全局定时器ID
  userId: null,
  live_msg: '',
  visiMsgModal: false
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    console.log('111')
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  setUserInfo(state, p) {
    state.userInfo = p
  },
  getDashboardData(state, p) {
    state.dashboardData = p
  },
  getAccInfo(state, p) {
    state.agentAccData = p
  },
  getDlTb(state, p) {
    state.dlAccTbData.push(p)
  },
  getAccObj(state, p) {
    state.dlAccObj = p
  },
  // 代理账户明细
  setRewardAcc(state, p) {
    state.rewardDetail = p
  },
  setSource(state, p) {
    state.source = p
  },
  // 页数总数
  setPageTotal(state, p) {
    state.pageTotal = p
  },
  setSourceLoading(state, p) {
    state.sourceLoading = p
  },
  // 获取私信数量
  setUnreadMessageCount(state, p) {
    state.unreadCount = Number(p)
  },
  setIsShowBadge(state, p) {
    state.isShowBadge = p
  },
  setVisiQrCode(state, p) {
    state.visiQrCode = p
  },
  setVisiChannelQrCode(state, p) {
    state.visiChannelQrCode = p
  },
  setIntervalId(state, intervalId) {
    state.intervalId = intervalId
  },
  setUserId(state, userId) {
    state.userId = userId
  },
  setLiveMsg(state, val) {
    state.live_msg = val
  },
  setVisiMsgModal(state, bool) {
    state.visiMsgModal = bool
  }
}

const actions = {
  timeTask({commit},obj) {
    let formDataabc = new FormData()
    for(let k in obj) {
      formDataabc.append(k, obj[k])
    }
    axios({
      method: "POST",
      url: "/request/user.php",
      data: formDataabc
    }).then((res) => {
      if(res.data.code == 200) {
        if(res.data.data.live_msg != '') {
          commit('setLiveMsg', res.data.data.live_msg)
          commit('setVisiMsgModal', true)
          Notification({
            title: '',
            message: res.data.data.live_msg,
            duration: 5000
          })
        }
        
      }
    })
  },
  // 开启定时请求接口
  openTimeTask({ commit, dispatch }, userId) {
    commit("setUserId", userId)
    let data = {
      type: 'time_request',
      uid: userId
    }
    if(state.intervalId == null && state.userId != null) {
      
      const intervalId = setInterval(() => {
        dispatch('timeTask', data)
        // timeTask(data)
      }, 10000)
      commit('setIntervalId', intervalId)
    }
  },
  // 获取代理账户明细
  getAccDetails(t, p) {

    if(p) {
      // let obj = {
      //   type: p.type,
      //   uid: p.uid,
      //   before: p.before,
      //   after: p.after,
      //   atype: p.atype,
      //   page: p.page,
      //   page_size: p.page_size
      // }
      getAgentAccDetail(p).then((res) => {
        if(res.code == 200) {
          t.commit("setRewardAcc", res.data.data)
          t.commit("setPageTotal", Number(res.data.size))
        }
      })
    }else {
      let data = {
        type: "get_agent_transaction",
        uid: window.localStorage.getItem("uid"),
        before: "",
        after: "",
        atype: "",
        page: 1,
        page_size: 20
      }
      getAgentAccDetail(data).then((res) => {
        if(res.code == 200) {
          t.commit("setRewardAcc", res.data.data)
          t.commit("setPageTotal", Number(res.data.size))
        }
      })
    }

    
  },
  // 获取来源
  getSourceData(t, p) {
    let obj = {
      type: "get_reward_source",
      uid: localStorage.getItem('uid'),
      ref_id: p
    }
    let formData = new FormData()
    for(let k in obj) {
      formData.append(k, obj[k])
    }
    axios({
      method: "POST",
      url: "/request/user.php",
      data: formData
    }).then((res) => {
      console.log("sorce vuex==>", res)
      if(res.data.code == 200) {
        t.commit("setSource", res.data.data)
        t.commit("setSourceLoading", false)
      }
    })
    // getAgentAccDetailSource(obj).then((res) => {
    //   console.log("sorce vuex==>", res)
    //   if(res.code == 200) {
        
    //     t.commit("setSource", res.data)
    //   }
    // })
  },
  // 获取未读私信数量
  getUnreadCount(t) {
    let data = {
      type: 'get_user_unread_message_count',
      uid: window.localStorage.getItem('uid')
    }
    getUserUnreadMessageCount(data).then((res) => {
      console.log('unread count==>', res)
      t.commit('setUnreadMessageCount', Number(res.data))
      if(res.data == 0 || res.data == '0') {
        t.commit('setIsShowBadge', false)
      }else {
        t.commit('setIsShowBadge', true)
      }
      
    })
  },
  // 獲取代理賬戶
  getAgentAcc(c) {
    let obj = {
      type: "get_agent_acc_info",
      uid: window.localStorage.getItem('uid')
    }
    getAgentAccInfo(obj).then((res) => {
      console.log('vuex res==>', res)
      if(res.code == 200) {
        c.commit("getAccInfo", res.data)
        // c.commit("getDlTb", res.data.agentAccData.bonus)
        let bin = ""
        let obj = {}
        c.commit("getAccObj", res.data.bonus)
        for(let k in res.data.bonus) {
          
          // c.commit("getDlTb", res.data.bonus[k])
          res.data.bonus[k].bins = k
          console.log('vuex kkkk==>', k,res.data.bonus[k])

          c.commit("getDlTb", res.data.bonus[k])
          
          // Object.values(res.data.bonus[k]).forEach((item,index) => {
          //   console.log("vuex obj==>", item.CardFee,index)
          // })
          // obj.bin = k

          // c.commit("getDlTb", obj)


        }
      }
    })
  },
  Logout({ commit }) {
    let loading = Loading.service({
      fullscreen: true
    })
    if(state.intervalId) {
      clearInterval(state.intervalId)
      commit('setIntervalId', null);
    }
    commit('setUserId', null);
    let data = {
      type: "logout"
    }
    let formData = new FormData()
    for(let k in data) {
      formData.append(k, data[k])
    }

    axios({
      method: "POST",
      url: "/request/user.php",
      data: formData
    }).then(res => {
      delCookie('_cf_bm')
          delCookie('_ga_HSZXNYSSWE')
          // delCookie('ckey')
          delCookie('_ga')
          delCookie('real_ipd')
          delCookie('PHPSESSID')
          delCookie('_gid')
          delCookie('cf_clearance')
          window.localStorage.removeItem('uid')
        setTimeout(() => {
          loading.close()
          // clearAllCookie()
          
          window.sessionStorage.clear()
          // clearAllCookie()
        // window.localStorage.clear()
        router.push("/pages/login")
        }, 2000)
    })
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions
})