
import service from "./api";

// 首頁數據
export function getHome(data) {
    return service({
        url: "/request/user.php",
        method: "POST",
        data: data
    })
}

// 虛擬卡列表
export function getCardList(data) {
    return service({
        url: "/request/user.php",
        method: "POST",
        data: data
    })
}

// 获取卡明细
export function getCardDetail(data) {
    return service({
        url: "/request/user.php",
        method: "POST",
        data: data
    })
}

// 修改卡標簽
export function editTag(data) {
    return service({
        url: "/request/user.php",
        method: "POST",
        data: data
    })
}

// 获取账户明细
export function getTransctionDetail(data) {
    return service({
        url: "/request/user.php",
        method: "POST",
        data: data
    })
}

// 创建卡信息
export function getCardInfo(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 獲取匯金賬戶
export function getFundAccount(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 添加资金账户
export function addFundAccount(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 獲取賬戶設定信息
export function getAccountSetInfo(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 重置密碼
export function resetPwd(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 提交匯款證明
export function sumbmitDeposit(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 創建虛擬卡
export function sumbmitIssuCard(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 获取issuing卡信息
export function getIssuingCardInfo(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 切換語言
export function changeLanguages(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// get_cny_deposit_rate
// 获取人民币充值费率
export function getCnyDepositRate(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// create_alipay
// 创建支付宝支付二维码
export function createAlipay(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 虛擬卡退款
export function cardRefund(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 获取卡段配置
export function cardSettingInfo(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 虛擬卡增值
export function cardTopup(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 获取每张卡的详情
export function getSingleCardDetail(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// get_topup_info 获取卡充值和退款详情
export function getTopupRefundCardInfo(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 注销卡
export function deleteCard(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data,
        // loading: true
    })
}

// 提現 withdraw
export function withdraw(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 获取支付宝提现信息 get_ali_withdraw_info
export function getAliWithdrawInfo(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 獲取代理賬戶
export function getAgentAccInfo(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 獲取代理賬戶明细
export function getAgentAccDetail(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 獲取代理賬戶明细来源
export function getAgentAccDetailSource(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// get_agent_stistic
// 獲取代理统计数据
export function getAgentStistic(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 獲取統計柱狀圖數據
export function getAgentDailyStatement(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 转出余额到账户
export function transferToAcc(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// agent_withdraw 代理提現賬戶
export function agentWithdraw(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// card_statistic
export function cardStatistic(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 获取USDT入金地址
export function getTronAddress(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 获取微信登陸二維碼
export function getWxQrCode(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 微信掃碼登陸
export function wxLogin(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 解除微信绑定
export function unBingWx(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 支付验证码
export function getOtp(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 通告
export function getPageNotice(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 支付保證金
export function guaranteeDeposit(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 获取未读私信数量
export function getUserUnreadMessageCount(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 获取私信列表
export function getUserMessage(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 標記私信已讀
export function userMessageRead(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 郵箱驗證碼
export function getEmailCode(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 更新備用碼
export function updateRecoveryCode(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 2fa_recovery_code 2fa備用碼驗證
export function twofaRecoveryCode(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 導出報告
export function exportReport(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 獲取卡BIN
export function getCardBinInfo(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 獲取卡賬單地址
export function getCardBillingAddress(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 更新卡賬單地址
export function updateCardBillingAddress(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// 獲取卡處理中的過程
export function getCardOperation(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// login_check_user_name
export function loginCheckUserName(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

export function login(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data
    })
}

// wx_scan_login
export function wxScanLogin(data) {
    return service({
        url: "request/user.php",
        method: "POST",
        data: data,
        loading: true
    })
}

// 获取实时信息
export function timeTask(data) {
    return service({
        url: "request/user.php",
        method: 'POST',
        data: data
    })
}

// 获取‘爆金行动’信息
export function getBonusBoomInfo(data) {
    return service({
        url: 'request/user.php',
        method: 'POST',
        data: data
    })
}

// Google 提交
export function googleCode(data) {
    return service({
        url: 'request/user.php',
        method: 'POST',
        data: data
    })
}

// google二維碼及密鑰
export function googleQrCode(data) {
    return service({
        url: 'request/user.php',
        method: 'POST',
        data: data
    })
}
